import React from 'react';
import './Team.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';

function Team() {
    const teamMembers = [
        {
            name: 'Ganesh Manjeshwara P',
            title: 'Chartered Accountant & Company Secretary',
            description: `Ganesh is a highly accomplished Chartered Accountant from ICAI and Company Secretary from ICSI, 
                          known for delivering exceptional results in finance, accounting, and business advisory. 
                          With a Bachelor's in Business Management and a Law degree, Ganesh has over 20 years of experience, 
                          including roles as International Finance Manager and leadership positions at Religare and Boston Financials.`,
        },
        {
            name: 'Krishna Padakannaya',
            title: 'Finance and Management Professional',
            description: `Krishna is a seasoned finance and management professional with strong expertise in outsourcing, 
                          financial due diligence, and M&A. A member of ICAI, with a Bachelor's in Business Management and 
                          a Law degree, Krishna has led finance operations across APAC, US, and EMEA regions, 
                          holding senior roles at Price Waterhouse, Emmvee, MetricStream, and Concentrix.`,
        },
        {
            name: 'April Van Horn',
            title: 'Financial Professional',
            description: `April is a results-driven accounting professional with expertise in cash flow forecasting 
                          and complex reconciliations. She oversees accounts receivable/payable and interdepartmental coordination. 
                          With experience in implementing new software and enhancing internal controls, April has held positions 
                          at MON-YOUGH Community Services, Management Science Associates, and CP Industries.`,
        },
        {
            name: 'Kawsal G',
            title: 'Corporate Compliance Specialist',
            description: `Kawsal is an associate member of ICSI with a Bachelor's in Accounting. He has a strong background 
                          in corporate compliance and governance in the US and India, specializing in company incorporations, 
                          legal document drafting, and statutory compliance. Previously, he held positions at Ullas Kumar Associates and Boston Financials.`,
        },
    ];

    return (
        <div className="team-page">
            <h1 className="team-title-main">Our Team</h1>
            <p className="team-subtitle">Meet the professionals who are dedicated to managing your finances with excellence.</p>
            <div className="team-container">
                {teamMembers.map((member, index) => (
                    <div key={index} className="team-card">
                        <FontAwesomeIcon icon={faUserCircle} className="team-icon" />
                        <h2 className="team-name">{member.name}</h2>
                        <h3 className="team-title">{member.title}</h3>
                        <p className="team-description">{member.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Team;

