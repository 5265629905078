import React from 'react';
import './Services.css';

function Services() {
    const services = [
        {
            title: 'Accounting and Taxation Processing',
            description: `NexusGlobe provides expert offshore solutions to handle all your accounting and financial needs as a Virtual Accountant. 
                          We specialize in working with small and medium businesses worldwide, offering services such as bookkeeping, 
                          managing receivables and payables, and reconciliations. Our industry-best practices ensure smooth and hassle-free financial transactions.`,
        },
        {
            title: 'Tax & Regulatory Compliance',
            description: `NexusGlobe helps you meet your business tax compliance and advisory needs. Our corporate tax experts use their 
                          diverse experience and knowledge to offer seamless services for planning, financial accounting, and tax compliance.`,
        },
        {
            title: 'Consulting and Advisory',
            description: `At NexusGlobe, we understand that small and medium enterprises (SMEs) often lack access to quality advisory and consulting experts. 
                          This gap becomes evident when these companies aim to grow and improve their efficiency and profitability. 
                          We bridge this gap by providing expert counselors and strategists to assist with planning, budgeting, and strategy development.`,
        },
        {
            title: 'Back Office Solutions',
            description: `NexusGlobe offers comprehensive Backoffice Support to streamline CPA's and other professional firms' accounting and financial operations. 
                          Our services are designed to assist in managing their workload efficiently and effectively, including bookkeeping, 
                          financial reporting, tax preparation, account management, payroll processing, and audit support.`,
        },
    ];

    return (
        <div className="services-page">
            <h1 className="services-title">Our Services</h1>
            <hr className="title-underline" />
            <div className="services-container">
                {services.map((service, index) => (
                    <div key={index} className="service-card">
                        <h2 className="service-title">{service.title}</h2>
                        <p className="service-description">{service.description}</p>
                    </div>
                ))}
            </div>
            <p className="services-footer">
                Our team of skilled professionals ensures that your back-office operations run smoothly, allowing you to focus on your core business activities.
            </p>
        </div>
    );
}

export default Services;
