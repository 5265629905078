import emailjs from '@emailjs/browser';
import './ContactUs.css';
import React, { useState, useMemo } from 'react';

const ContactUs = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [popupClass, setPopupClass] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();
    const serviceID = 'service_ys8jiwb';
    const templateID = 'template_dddn30e';
    const publicKEY = 'ybqepsIbXZtbfapmE';

    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: "nexus",
      message: message,
    };

    emailjs.send(serviceID, templateID, templateParams, publicKEY)
      .then((result) => {
        setIsPopupOpen(true);
        setPopupClass('');
        setName('');
        setEmail('');
        setMessage('');

        setTimeout(() => {
          setPopupClass('fade-out');
        }, 1300);
      })
      .catch((error) => {
        alert("Failed to send message, please try again later.");
        console.error("Error:", error);
      });

    e.target.reset();
  };

  const locations = useMemo(() => [
    {
      title: "USA",
      address: "250 Cedar Ridge, Dr Apt 911, Monroeville, PA - 15146, USA",
      phone: "+1 4123788698",
      mapSrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3031.5254739792836!2d-79.77903658460594!3d40.42184407936371!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8834edaf8eb7365f%3A0x9464741f3093d2!2s250%20Cedar%20Ridge%20Dr%20Apt%20911%2C%20Monroeville%2C%20PA%2015146%2C%20USA!5e0!3m2!1sen!2sin!4v1678250676929!5m2!1sen!2sin"
    },
    {
      title: "Udupi, India",
      address: "# Skanda, Main Road, Korangapady, Udupi – 574 118",
      phone: "+91 9612535355",
      mapSrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63185.333373347344!2d74.70620961476368!3d13.340880704986264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbcbbde5e4a4d4b%3A0xb344d3b264f35b7b!2sUdupi%2C%20Karnataka%20574118!5e0!3m2!1sen!2sin!4v1678250676929!5m2!1sen!2sin"
    },
    {
      title: "Bangalore, India",
      address: "#180, 4th Floor, 16th Main, 36th Cross Rd, 4th T Block East, Pattabhirama Nagar, Jayanagar, Bengaluru - 560041, Karnataka, India",
      phone: "+91 9900244717",
      mapSrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62108.63422204752!2d77.52709772856906!3d12.953425616438993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15a8c378307d%3A0xbfd6a2f8b14a2197!2sJayanagar%2C%20Bengaluru%2C%20Karnataka%20560041!5e0!3m2!1sen!2sin!4v1678250676929!5m2!1sen!2sin"
    },
    {
      title: "Nagpur, India",
      address: "Shop No 16A, First Floor Popular, Lal Imli Chowk, Wholesale Cloth Market, Gandhibagh, Nagpur, Maharashtra 440002",
      phone: "",
      mapSrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62097.14243816857!2d79.04986097836882!3d21.1458005706466!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c072a4a46a5f%3A0x89a8417f0cf3486!2sNagpur%2C%20Maharashtra%20440002!5e0!3m2!1sen!2sin!4v1678250676929!5m2!1sen!2sin"
    }
  ], []);

  return (
    <div className="contact-page">
      <div className="contact-form">
        <h2>Contact Us</h2>
        <form id="contactForm" onSubmit={sendEmail}>
          <input
            type="text"
            placeholder="Your Name"
            required
            name="user_name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="email"
            placeholder="Your Email"
            required
            name="user_email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <textarea
            placeholder="Your Message"
            required
            name="user_message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          <button type="submit">Submit</button>
        </form>
      </div>

      {/* Simple Popup with fade effect */}
      {isPopupOpen && (
        <div className={`popup ${popupClass}`}>
          <div className="popup-content">
            SENT
          </div>
        </div>
      )}

      {/* Locations Section */}
      <div className="locations">
        <h2>Locations</h2>
        <div className="location-cards">
          {locations.map((location, index) => (
            <LocationCard key={index} location={location} />
          ))}
        </div>
      </div>
    </div>
  );
};

const LocationCard = React.memo(({ location }) => {
  return (
    <div className="location-card">
      <h3>{location.title}</h3>
      <p>{location.address}</p>
      <p>Phone: {location.phone}</p>
      <iframe
        src={location.mapSrc}
        title={`${location.title} Location Map`}
        allowFullScreen
        loading="lazy"
      ></iframe>
    </div>
  );
});

export default ContactUs;
