import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import './HomePage.css';

function HomePage() {
    // List of image paths
    const images = [
        '/hero-image1.jpg',
        '/hero-image2.jpg',
        '/hero-image3.jpg',
        '/hero-image4.jpg'
    ];

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    // Automatically change the image every 3 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000);

        // Clear the interval when the component is unmounted
        return () => clearInterval(interval);
    }, [images.length]);

    return (
        <div className="homepage">
            {/* Hero Section with Slider */}
            <div className="hero-section">
                <div className="image-slider">
                    {images.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`Slide ${index + 1}`}
                            className={`hero-image ${index === currentImageIndex ? 'active' : ''}`}
                        />
                    ))}
                </div>

                {/* Dots Navigation */}
                <div className="dots">
                    {images.map((_, index) => (
                        <span
                            key={index}
                            className={`dot ${index === currentImageIndex ? 'active' : ''}`}
                            onClick={() => setCurrentImageIndex(index)}
                        ></span>
                    ))}
                </div>

                <h1>Streamline Your Accounting and Tax Needs with Expert Outsourcing Solutions</h1>
                <p className="subtitle">Reliable Accounting, Bookkeeping, and Tax Preparation Services for Businesses in USA & India</p>
            </div>

            {/* Intro Section */}
            <div className="intro-text">
                <p>
                    Welcome to NexusGlobe, your trusted partner for outsourced accounting, bookkeeping, and tax preparation services. 
                    Whether you're a small business, a growing startup, or an established company, our team of certified professionals 
                    is here to help you manage your finances efficiently and cost-effectively. With expertise in both US and India regulations, 
                    we provide tailored solutions that save you time, reduce costs, and ensure compliance with local tax laws.
                </p>
            </div>

            {/* Features Section */}
            <div className="features">
                <div className="feature-box">
                    <h2>Integrity & Transparency</h2>
                    <p>We prioritize honesty in everything we do, offering clear, reliable communication. Our strong data security measures keep client information safe and confidential.</p>
                </div>
                <div className="feature-box">
                    <h2>Proactive Communication</h2>
                    <p>We focus on accountability and open communication to quickly resolve issues with integrity, building strong trust with our clients.</p>
                </div>
                <div className="feature-box">
                    <h2>Partnership & Collaboration</h2>
                    <p>We foster an environment where ideas are openly shared and discussed, encouraging personal responsibility in building strong relationships with both clients and colleagues.</p>
                </div>
            </div>

            {/* Call to Action Buttons Section */}
            <div className="button-section">
                <Link to="/contact-us" className="cta-button">
                    <FontAwesomeIcon icon={faPhone} /> Get a Free Consultation Today
                </Link>
                <Link to="/services" className="cta-button">
                    <FontAwesomeIcon icon={faInfoCircle} /> Learn More About Our Services
                </Link>
            </div>
        </div>
    );
}

export default HomePage;
