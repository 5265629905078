import React from 'react';
import './AboutUs.css';

const AboutUs = () => {
    return (
        <div className="page-background">
            <div className="about-us-container">
                <h2 className="about-us-title">About Us</h2>
                <div className="section mission-statement">
                    <h3 className="section-title">MISSION STATEMENT</h3>
                    <p className="section-content">
                        Our mission is to empower businesses by streamlining their accounting, bookkeeping, and tax processes with reliable and efficient services.
                    </p>
                </div>
                <div className="section vision-statement">
                    <h3 className="section-title">VISION STATEMENT</h3>
                    <p className="section-content">
                        We envision a future where businesses of all sizes can focus on growth and innovation, knowing their finances are handled with expertise.
                    </p>
                </div>
                <div className="divider"></div>
                <div className="section expertise">
                    <h3 className="section-title">EXPERTISE</h3>
                    <p className="section-content">
                        With more than 100 years of team experience in serving clients across both the United States and India, our team understands the complexities of each country’s financial landscape. We specialize in cross-border compliance, offering personalized services that align with your unique needs—whether it’s managing day-to-day bookkeeping or preparing taxes under the U.S. IRS or India’s GST framework. Our services are designed to minimize errors, save you money, and provide transparent financial reporting.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;